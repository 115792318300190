import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { API } from 'aws-amplify';
import { 
    listTaskByCalendarIDuserID
} from './graphql/queries';
import { 
    updateUserFn as updateUserFnMutation
} from './graphql/mutations';
import {
    EuiTourStep,
    EuiPopover,
    EuiFormRow,
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiSpacer,
    EuiModalFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiButtonIcon,
    EuiInMemoryTable,
    EuiStat,
    EuiTab,
    EuiTabs,
    EuiLoadingChart,
    EuiPopoverTitle,
    EuiProgress,
    EuiPageTemplate,
    EuiSuperDatePicker,
    EuiSelectable,
    useEuiTheme,
    logicals
} from '@elastic/eui';
import { css } from '@emotion/react';
import { Chart, Partition, BarSeries, Axis, ScaleType,PartitionLayout,Settings,SeriesColorAccessor,PartialTheme } from '@elastic/charts';
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import '@elastic/charts/dist/theme_only_light.css';
import dateMath from '@elastic/datemath';
import { CSVLink } from 'react-csv';
import {
    defaultProjects,
    statusStrings,
    difficultyMult,
    focusMult,
    difficultyStrings,
    focusStrings
} from './Constants';

const partitionTheme = {
  background: {
    color: 'rgba(255, 255, 255, 1)',
  },
  chartMargins: { top: 0, left: 0, bottom: 0, right: 0 },
  partition: {
    minFontSize: 1,
    maxFontSize: 20,
    idealFontSizeJump: 1.1,
    outerSizeRatio: 1,
    emptySizeRatio: 0,
    circlePadding: 4
  }
};

function ExpandReport({expandModal, setExpandModal, events, projects, breakDownByTimeSpent, breakDownByValue, euiChartTheme, euiPartitionConfig}) {
    const closeModal = () => setExpandModal("");
    const [graphData,setGraphData] = useState([]);

    useEffect(()=>{
        if(events) {
        if(expandModal === "Project Breakdown by TimeSpent") {
            setGraphData(breakDownByTimeSpent(events, "project"));
        } else if(expandModal === "Goal Breakdown by TimeSpent") {
            setGraphData(breakDownByTimeSpent(events, "goal"));
        } else if(expandModal === "Combined Breakdown by TimeSpent") {
            setGraphData(breakDownByTimeSpent(events, "combined"));
        } else if(expandModal === "Project Breakdown by Tasks") {
            setGraphData(breakDownByValue(events, "project"));
        } else if(expandModal === "Goal Breakdown by Tasks") {
            setGraphData(breakDownByValue(events, "goal"));
        } else if(expandModal === "Combined Breakdown by Tasks") {
            setGraphData(breakDownByValue(events, "combined"));
        }
        }
    },[events, expandModal, breakDownByTimeSpent, breakDownByValue]);

    return(
        <>
        {expandModal &&
        <EuiModal maxWidth={false} onClose={closeModal}>
            <EuiModalHeader>
            <EuiModalHeaderTitle>
                <h1>{expandModal}</h1>
            </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
            {expandModal.includes("Combined") ? 
                (expandModal.includes("TimeSpent") ? 
                <Chart size={{height: 700}}>
                <Settings
                  theme={{
                    partition: partitionTheme,
                    chartMargins: { top: 0, left: 0, bottom: 0, right: 0 }
                  }}
                />
                <Partition
                    data={graphData}
                    layout={PartitionLayout.sunburst}
                    valueAccessor={d => Number(d.count)}
                    valueFormatter={d => (Math.round(d * 10) / 10)+`h`}
                    layers={[
                    {
                        groupByRollup: d => d.goalName,
                        shape: {
                        fillColor: d => euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length],
                        },
                    },
                    {
                        groupByRollup: d => d.j,
                        shape: {
                        fillColor: d => {
                            let projectObj = projects.find( ({ text }) => text === d.dataName );
                            if(projectObj) {
                            if(projectObj.color) {
                                return projectObj.color;
                            }
                            }
                            return euiChartTheme.theme.colors.vizColors[d.parent.sortIndex % euiChartTheme.theme.colors.vizColors.length];
                        }
                        }  
                    }
                    ]}
                    config={{
                    ...euiPartitionConfig,
                    clockwiseSectors: false, // For correct slice order
                    }}
                />
                </Chart> 
                :
                <Chart size={{height: 700}}>
                <Settings
                  theme={partitionTheme}
                />
                <Partition
                    data={graphData}
                    layout={PartitionLayout.sunburst}
                    valueAccessor={d => Number(d.count)}
                    layers={[
                    {
                        groupByRollup: d => d.goalName,
                        shape: {
                        fillColor: d => euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length],
                        },
                    },
                    {
                        groupByRollup: d => d.j,
                        shape: {
                        fillColor: d =>  {
                            let projectObj = projects.find( ({ text }) => text === d.dataName );
                            if(projectObj) {
                            if(projectObj.color) {
                                return projectObj.color;
                            }
                            }
                            return euiChartTheme.theme.colors.vizColors[d.parent.sortIndex % euiChartTheme.theme.colors.vizColors.length];
                        }
                        }
                    }
                    ]}
                    config={{
                    ...euiPartitionConfig,
                    clockwiseSectors: false, // For correct slice order
                    }}
                />
                </Chart> 
                )
                : 
                (expandModal.includes("TimeSpent") ? 
                <Chart size={{height: 700}}>
                <Settings
                  theme={partitionTheme}
                />
                <Partition
                    data={graphData}
                    valueAccessor={d => Number(d.count)}
                    valueFormatter={d => (Math.round(d * 10) / 10)+`h`}
                    layers={[
                    {
                        groupByRollup: d => d.j,
                        shape: {
                        fillColor: d =>  {
                            let projectObj = projects.find( ({ text }) => text === d.dataName );
                            if(projectObj) {
                            if(projectObj.color) {
                                return projectObj.color;
                            }
                            }
                            return euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length];
                        }
                        }
                    }
                    ]}
                    config={{
                    ...euiPartitionConfig,
                    clockwiseSectors: false, // For correct slice order
                    }}
                />
                </Chart>
                :
                <Chart size={{height: 700}}>
                <Settings
                  theme={partitionTheme}
                />
                <Partition
                    data={graphData}
                    valueAccessor={d => Number(d.count)}
                    layers={[
                    {
                        groupByRollup: d => d.j,
                        shape: {
                        fillColor: d =>  {
                            let projectObj = projects.find( ({ text }) => text === d.dataName );
                            if(projectObj) {
                            if(projectObj.color) {
                                return projectObj.color;
                            }
                            }
                            return euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length];
                        }
                        }
                    }
                    ]}
                    config={{
                    ...euiPartitionConfig,
                    clockwiseSectors: false, // For correct slice order
                    }}
                />
                </Chart>
                )
            }
            <ExpandTableBreakDown type={expandModal} events={events} projects={projects}/>
            </EuiModalBody>

            <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
                Close
            </EuiButton>
            </EuiModalFooter>
        </EuiModal>
        }
        </>
    );
}

function TagAnalysis({tagData}) {
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(()=>{
    let tagDataArr = [];
    for(let i in tagData) {
      let tagName = i;
      for(let y in tagData[i]) {
        let taskName = y;
        let numTag = tagData[i][y];
        tagDataArr.push({
          "meeting": taskName,
          "tag": tagName,
          "numTag": numTag
        });
      }
    }
    setItems(tagDataArr);
    let newColumns = [
      {
          field: 'meeting',
          name: 'Meeting',
          width: '40%',
          sortable: true
      },
      {
          field: 'tag',
          name: 'Tag',
          width: '20%',
          sortable: true
      },
      {
          field: 'numTag',
          name: 'Number of Tags',
          width: '20%',
          sortable: true,
          dataType: "number"
      }
    ];

    setColumns(newColumns);
  },[tagData]);

  return (
    <>
      <CSVLink data={items} filename="TimeBlokExportByTags" withTimeStamp>
        <EuiButton>Export CSV</EuiButton>
      </CSVLink>
      <EuiSpacer/>
      <EuiInMemoryTable
          items={items}
          columns={columns}
          sorting={true}
          pagination={true}
      />
    </>
  );
}

function ProjectTable({projectByAlias, aliasProjects, setAliasProjects}) {
  const [isSelectProjectPopoverOpen,setSelectProjectPopoverOpen] = useState(false);
  const [graphData,setGraphData] = useState([]);
  const selectedProject = aliasProjects.find((project)=>project.checked === "on");
  const { euiTheme } = useEuiTheme();

  useEffect(()=>{
    console.log(aliasProjects);
  },[aliasProjects]);

  useEffect(()=>{
    if(selectedProject) {
      let newGraphData = [];
      for(let i in projectByAlias[selectedProject.value]) {
        newGraphData.push({"alias": i.replaceAll("amazonfederate_",""), "count": projectByAlias[selectedProject.value][i].toFixed(2)})
      }
      newGraphData.sort((a,b)=>b.count-a.count);
      setGraphData(newGraphData);
    }
  },[projectByAlias,selectedProject])

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem
          css={css`
              padding: calc(${euiTheme.size.xs});
          `}>
          <EuiPopover
            panelPaddingSize="m"
            button={
              <EuiButton
                iconType="arrowDown"
                iconSide="right"
                onClick={() => setSelectProjectPopoverOpen(!isSelectProjectPopoverOpen)}
              >
                Select Projects
              </EuiButton>
            }
            isOpen={isSelectProjectPopoverOpen}
            closePopover={() => setSelectProjectPopoverOpen(false)}
          >
            <EuiSelectable
              singleSelection={true}
              searchable
              searchProps={{
                placeholder: 'Filter list',
                compressed: true,
              }}
              options={aliasProjects}
              onChange={(newOptions) => {
                setAliasProjects(newOptions);
              }}
            >
              {(list, search) => (
                <div style={{ width: 240 }}>
                  <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                  {list}
                </div>
              )}
            </EuiSelectable>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer/>
      <EuiFlexGroup>
        <EuiFlexItem>
          {  selectedProject &&
            <EuiPanel>
              <EuiText style={{ textAlign: 'center' }}>
                <h4>{selectedProject.text} by Alias</h4>
              </EuiText>
              <Chart size={{height: 700}}>
                <Settings
                  theme={{
                    barSeriesStyle: {
                      displayValue: {
                        fontSize: 12,
                        fontStyle: 'bold',
                        fill: { textBorder: 1 },
                        offsetX: 10,
                        offsetY: 0,
                        alignment: {
                          vertical: 'middle',
                        },
                      },
                    },
                  }}
                  rotation={90}
                />
                <BarSeries
                  id="projectByAlias"
                  name="Timespent"
                  xAccessor="alias"
                  yAccessors={['count']}
                  data={graphData}
                  displayValueSettings={{showValueLabel: true}}
                />
                <Axis
                  id="bottom-axis"
                  position={"left"}
                  gridLine={{ visible: false }}
                />
                <Axis
                  id="left-axis"
                  position={"bottom"}
                  title="Timespent (hrs)"
                />
              </Chart>
            </EuiPanel>
          }
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

function AdoptionTable({teamGroupByData,totalHoursByAlias}) {
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(()=>{
        let newteamGroupByData = {...teamGroupByData};
        for(let i in newteamGroupByData) {
        let hours = 0;
        if(totalHoursByAlias[i]) {
            hours = totalHoursByAlias[i];
        }
        newteamGroupByData[i].hours = parseInt(hours);
        newteamGroupByData[i].alias = i.replaceAll("amazonfederate_","");
        }
        const teamGroupByDataArr = Object.values(newteamGroupByData);
        setItems(teamGroupByDataArr);

        let newColumns = [
        {
            field: 'alias',
            name: 'Alias',
            width: '15%',
            sortable: true
        },
        {
            field: 'hours',
            name: 'Hours Logged',
            width: '10%',
            sortable: true,
            dataType: "number"
        },
        {
            field: 'tenure',
            name: 'Tenure',
            width: '10%',
            sortable: true
        },{
            field: 'level',
            name: 'Level',
            width: '10%',
            sortable: true
        },{
            field: 'manager',
            name: 'Manager',
            width: '15%',
            sortable: true
        },
        {
            field: 'leaderofleader',
            name: 'Leader of Leader',
            width: '15%',
            sortable: true
        }
        ];

        setColumns(newColumns);
    },[teamGroupByData,totalHoursByAlias]);

    return (
        <>
          <CSVLink data={items} filename="TimeBlokExportByProject" withTimeStamp>
              <EuiButton>Export CSV</EuiButton>
          </CSVLink>
          <EuiSpacer/>
          <EuiInMemoryTable
              items={items}
              columns={columns}
              sorting={true}
              pagination={true}
          />
        </>
    );
}
  
function ExpandTableBreakDown({type, events, projects}) {
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(()=>{
        if(type === "Project Breakdown by TimeSpent") {
          let columns = [
              {
              field: 'alias',
              name: 'Subject',
              width: '15%',
              sortable: true
              }
          ];
          for(let i in projects) {
              if(projects[i].team || defaultProjects[projects[i].value]) {
              columns.push({
                  field: projects[i].text,
                  name: projects[i].text,
                  sortable: true
              });
              }
          }

          columns.push({
            field: 'totalhours',
            name: 'Total Hours',
            sortable: true
          });

          columns.push({
            field: 'totalusers',
            name: 'Total Users',
            sortable: true
          });

          setColumns(columns);

          let users = {};
          users["Team"] = {};
          users["Team"].total=0;
          
          for(let i in events) {
              if(!users[events[i].task.userID]) {
                users[events[i].task.userID] = {};
                users[events[i].task.userID].total=0;
                users[events[i].task.userID].owners={};
              }
              if(events[i].task.owner.includes("::")) {
                events[i].task.owner = events[i].task.owner.split("::")[1];
              }
              if(!users[events[i].task.userID].owners[events[i].task.owner]){
                users[events[i].task.userID].owners[events[i].task.owner] = 1;
              }
              if(!events[i].task.project.name) {
                let projectObj = projects.find( ({ key }) => key === events[i].task.projectID );
                if(projectObj) {
                    events[i].task.project.name = projectObj.text;
                } else {
                    events[i].task.project.name = "Unknown Project";
                }
              }
              if(!users[events[i].task.userID][events[i].task.project.name]) {
                users[events[i].task.userID][events[i].task.project.name] = 0;
              }
              if(!users["Team"][events[i].task.project.name]) {
                users["Team"][events[i].task.project.name] = 0;
              }
              users[events[i].task.userID][events[i].task.project.name]+=(events[i].task.duration/3600);
              users[events[i].task.userID].total+=(events[i].task.duration/3600);
              users["Team"][events[i].task.project.name]+=(events[i].task.duration/3600);
              users["Team"].total+=(events[i].task.duration/3600);
          }
          let teamMetric = null;
          let newItems = [];
          let teamUsers = 0;
          for(let i in users) {
              let totalusers = 0;
              if(users[i].owners) {
                totalusers = Object.keys(users[i].owners).length;
                teamUsers+=totalusers;
              }
              let userMetric = {
                "alias": i.replaceAll("amazonfederate_",""),
                "totalhours": users[i].total.toFixed(1),
                "totalusers": totalusers
              };
              for(let j in projects) {
                if(projects[j].team || defaultProjects[projects[j].value]) {
                    userMetric[projects[j].text] = 0;
                    if(users[i][projects[j].text]) {
                      userMetric[projects[j].text] = ((users[i][projects[j].text]/users[i].total)*100).toFixed(1)+"%";
                    }
                }
              }
              if(i === "Team") {
                teamMetric = userMetric;
              } else {
                newItems.push(userMetric);
              }
          }
          teamMetric.totalusers = teamUsers;
          newItems.push(teamMetric);
          setItems(newItems);
        }
    },[events,projects,type]);

    return (
        <>
        <CSVLink data={items} filename="TimeBlokExportByProject" withTimeStamp>
            <EuiButton>Export CSV</EuiButton>
        </CSVLink>
        <EuiSpacer/>
        <EuiInMemoryTable
            items={items}
            columns={columns}
            sorting={true}
            pagination={true}
        />
        </>
    );
}

export function Reports({reportStart, setReportStart, reportEnd, setReportEnd, reportEvents, setReportEvents, reportProjects, setReportProjects, reportType, euiTourSteps, tourActions,userSettings,setUserSettings,userData,reportCalendars,setReportCalendars, defaultProjectsMap, teamGroupByData, teamProjects, reportAlias, setReportAlias}) {
    const [isLoading, setIsLoading] = useState(true);
    const [recentlyUsedRanges, setRecentlyUsedRanges] = useState([]);
    const [timeSpentLineData,setTimeSpentLineData] = useState([]);
    const [timeSpentLineDataByGroup,setTimeSpentLineDataByGroup] = useState([]);
    const [goalLineData,setGoalLineData] = useState([]);
    const [expandModal,setExpandModal] = useState(false);
    const [isSelectProjectPopoverOpen,setSelectProjectPopoverOpen] = useState(false);
    const [isSelectAliasPopoverOpen,setSelectAliasPopoverOpen] = useState(false);
    const [isSelectCalendarPopoverOpen,setSelectCalendarPopoverOpen] = useState(false);
    const [isSelectGroupByPopoverOpen,setSelectGroupByPopoverOpen] = useState(false);
    const [isSelectGroupByValuesPopoverOpen,setSelectGroupByValuesPopoverOpen] = useState(false);
    const [isSelectManagerLoLValuesPopoverOpen,setSelectManagerLoLValuesPopoverOpen] = useState(false);
    const [isLoadingCalendars,setIsLoadingCalendars] = useState(false);
    const [isLoadingProjects,setIsLoadingProjects] = useState(false);
    const [isLoadingAlias,setIsLoadingAlias] = useState(false);
    const [isLoadingGroupBy,setIsLoadingGroupBy] = useState(false);
    const [isLoadingGroupByValues,setIsLoadingGroupByValues] = useState(false);
    const [isLoadingManagerLoLValues,setIsLoadingManagerLoLValues] = useState(false);
    const [groupBySelection,setGroupBySelection] = useState([
      {"value":"tenure","label":"Tenure","checked": "on"},
      {"value":"level","label":"Level"},
      {"value":"manager","label":"Manager"},
      {"value":"leaderofleader","label":"Leader of Leader"},
    ]);
    const [groupByValues,setGroupByValues] = useState([]);
    const [managerLoLValues,setManagerLoLValues] = useState([]);
    const [groupByTitle,setGroupByTitle] = useState("tenure");
    const [loadProgress,setLoadProgress] = useState(0);
    const [loadMax,setLoadMax] = useState(100);
    const [selectedTabId,setSelectedTabId] = useState("Overall");
    const [totalHoursByAlias,setTotalHoursByAlias] = useState({});
    const [eventsRangeFilteredByGroup, setEventsRangeFilteredByGroup] = useState([]);
    const [eventsRangeFilteredByGroupValue, setEventsRangeFilteredByGroupValue] = useState([]);
    const [tagData,setTagData] = useState({});
    const [goalGrouping, setGoalGrouping] = useState(1);
    const [realAvgDays,setRealAvgDays] = useState(0);
    const [projectByAlias,setProjectByAlias] = useState({});
    const [aliasProjects,setAliasProjects] = useState(reportProjects.map((project)=>({...project, checked:undefined})));
  
    const eventsRangeFiltered = useMemo(()=>{
      const startMoment = dateMath.parse(reportStart);
      const endMoment = dateMath.parse(reportEnd);
      return reportEvents.filter((event)=> {
        let returnProject = false;
        let returnDate = false;
        let returnAlias = false;
        //Filter projects
        let foundProject = reportProjects.find(elem2=>(elem2.key === event.task.projectID));
        if(foundProject) {
          if(foundProject.checked === 'on') {
            returnProject = true;
          }
        } else if(defaultProjectsMap[event.task.projectID]) {
          returnProject = true;
        } 
        //Filter start date and end date
        if(moment(event.task.startDateTime,moment.ISO_8601).diff(startMoment) >= 0 && moment(event.task.startDateTime,moment.ISO_8601).diff(endMoment) <= 0) {
          returnDate = true;
        }
        //Filter alias
        let foundAlias = reportAlias.find(elem2=>(elem2.key === event.task.owner));
        if(foundAlias) {
          if(foundAlias.checked === 'on') {
            returnAlias = true;
          }
        }
        return (returnDate && returnProject && returnAlias);
      }).sort((a,b) => new moment(a.task.startDateTime,moment.ISO_8601).format('YYYYMMDD') - new moment(b.task.startDateTime,moment.ISO_8601).format('YYYYMMDD')); 
    },[reportEvents, reportProjects, reportAlias, reportStart, reportEnd, defaultProjectsMap]);

    const avgDays = useMemo(() => {
      const startMoment = dateMath.parse(reportStart);
      const endMoment = dateMath.parse(reportEnd);
      let _avgDays = 1;
      if(startMoment && endMoment) {
        _avgDays = endMoment.diff(startMoment,'weeks')*userSettings.workWeek;
        if(_avgDays === 0) {
          _avgDays = Math.ceil(endMoment.diff(startMoment,'days'));
        }
      }
      return _avgDays
    }, [reportStart,reportEnd,userSettings]);
    
    const euiChartTheme = EUI_CHARTS_THEME_LIGHT;
    const euiPartitionConfig = euiChartTheme.partition;
    const { euiTheme } = useEuiTheme();
  
    const barSeriesColorAccessor = ({ specId, yAccessor, splitAccessors }) => {
      let projectObj = reportProjects.find( ({ text }) => text === splitAccessors.get('j') );
      if(projectObj) {
        if(projectObj.color) {
          return projectObj.color;
        }
      }
      return null;
    };
  
    const taskList = eventsRangeFiltered.map((elem)=>{
      let projectObj = reportProjects.find( ({ key }) => key === elem.task.projectID );
      if(!projectObj) {
        if(defaultProjects[elem.task.projectID]) {
          elem.task.project = {name: defaultProjects[elem.task.projectID]};
        } else {
          elem.task.project = {name: "Unknown Project"};
        }
        elem.task.project.goal = {name: "Unassigned"};
      } else {
        elem.task.project = {name: projectObj.text};
        if(!projectObj.goal) {
          elem.task.project.goal = {name: "Unassigned"};
        } else {
          elem.task.project.goal = projectObj.goal;
        }
      }
  
      return {
        taskName: elem.task.name,
        taskNotes: elem.task.description,
        taskTags: elem.task.tags,
        userAlias: elem.task.owner.replaceAll("amazonfederate_",""),
        projectName: elem.task.project.name,
        projectTag: elem.task.projectTag,
        goalName: elem.task.project.goal.name,
        "timeSpent(Hrs)": (elem.task.timeSpent/3600),
        "duration(Hrs)": (elem.task.duration/3600),
        status: statusStrings[elem.task.status],
        teamID: elem.task.teamID,
        startDateTime: moment(elem.task.startDateTime,moment.ISO_8601).format('YYYY-MM-DD HH:mm:ss')
      };
    });
  
    const onTimeChange = async ({ start, end }) => {
      const delayDebounceFn = setTimeout(() => {
        const recentlyUsedRange = recentlyUsedRanges.filter((recentlyUsedRange) => {
          const isDuplicate =
            recentlyUsedRange.start === start && recentlyUsedRange.end === end;
          return !isDuplicate;
        });
        recentlyUsedRange.unshift({ start, end });
        setReportStart(start);
        setReportEnd(end);
        let startEndString = start+"^"+end;
        API.graphql({ query: updateUserFnMutation, variables: { input: {'reportsDateRange':startEndString}} }).catch((error)=>{});
        setUserSettings({...userSettings, 'reportsDateRange':startEndString })
        setRecentlyUsedRanges(
          recentlyUsedRange.length > 10
            ? recentlyUsedRange.slice(0, 9)
            : recentlyUsedRange
        );
        setIsLoading(true);
    
        tourActions.incrementStep();
      }, 1000)
  
      return () => clearTimeout(delayDebounceFn)
    };
  
    const onRefresh = ({ start, end, refreshInterval }) => {
      return new Promise((resolve) => {
        setTimeout(resolve, 100);
      }).then(() => {
        console.log(start, end, refreshInterval);
      });
    };
  
    function getTotal(eventsArr, workType, totalType) {
      let total = 0;
      for(let i in eventsArr) {
        let valueToAdd = eventsArr[i].task[totalType];
        if(workType === "deepWork") {
          valueToAdd = ((valueToAdd/3600)*difficultyMult[eventsArr[i].task.difficulty]*focusMult[eventsArr[i].task.focus]).toFixed(1);
        } else if(workType === "timeSpent" || workType === "duration") {
          valueToAdd = valueToAdd/3600;
        }
        total += Number(valueToAdd);
      }
      return total;
    }
  
    const timeSpentTotalByDay = useCallback((eventsArr, reportType) => {
      let tempObj = {};
      for(let i in eventsArr) {
        let projectName = "";
        let projectObj = reportProjects.find( ({ key }) => key === eventsArr[i].task.projectID );
        if(projectObj) {
          projectName = projectObj.text;
        } else {
          if(defaultProjects[eventsArr[i].task.projectID]) {
            projectName = defaultProjects[eventsArr[i].task.projectID];
          } else {
            projectName = "Unknown Project";
          }
        }
        let eventTitle = moment(eventsArr[i].task.startDateTime,moment.ISO_8601).format('MMM Do');
        if(reportType === "Team") {
          eventTitle = eventsArr[i].task.userID;
        }
        if(!tempObj[eventTitle]) {
          tempObj[eventTitle] = {};
          tempObj[eventTitle]["total"] = 0;
        } 
        if(!tempObj[eventTitle][projectName]) {
          tempObj[eventTitle][projectName] = 0;
        }
        let valueToAdd = (eventsArr[i].task.duration/3600).toFixed(1);
        tempObj[eventTitle][projectName] += Number(valueToAdd);
        tempObj[eventTitle]["total"] += Number(valueToAdd);
      }
      if(reportType === "Team") {
        for(let i in tempObj) {
          for(let y in tempObj[i]) {
            if(y !== "total") {
              tempObj[i][y] = ((tempObj[i][y] / tempObj[i]["total"]) * 100).toFixed(0);
            }
          }
        }
      }
      let returnArr = [];
      for(let i in tempObj) {
        for(let j in tempObj[i]) {
          if(j !== "total") {
            let obj = {
              x: i,
              y: tempObj[i][j],
              j: j
            };
            returnArr.push(obj);
          }
        }
      }
      return returnArr;
    },[reportProjects]);
  
    const breakDownByValue = useCallback((eventsArr, value) => {
      let tempObj = {};
      
      for(let i in eventsArr) {
        if(value === "project" || value === "goal" || value === "combined") {
          let projectName = "";
          let goalName = "";
          let projectObj = reportProjects.find( ({ key }) => key === eventsArr[i].task.projectID );
          if(projectObj) {
            projectName = projectObj.text;
            if(projectObj.goal) {
              goalName = projectObj.goal.name;
            } else {
              goalName = "GoalNotAssigned";
            }
          } else {
            goalName = "GoalNotAssigned";
            if (eventsArr[i].task.outlookNotAccepted) {
              projectName = "NotAccepted";
            } else if(defaultProjects[eventsArr[i].task.projectID]) {
              projectName = defaultProjects[eventsArr[i].task.projectID];
            } else {
              projectName = "Unknown Project";
            }
          }
          if(!tempObj[goalName]) {
            tempObj[goalName] = {};
            tempObj[goalName]["total"] = 0;
            tempObj[goalName]["projects"] = {};
          }
          if(!tempObj[goalName]["projects"][projectName]) {
            tempObj[goalName]["projects"][projectName] = 1;
          } else {
            tempObj[goalName]["projects"][projectName]++;
          }
          tempObj[goalName]["total"]++;
        }
      } 
      let returnArr = [];
      if(value === "combined" || value === "project") {
        for(let i in tempObj) {
          for(let y in tempObj[i]["projects"]) {
            let obj = {
              projectName: y,
              goalName: i,
              count: tempObj[i]["projects"][y]
            }
            returnArr.push(obj);
          }
        }
      } else if(value === "goal") {
        for(let i in tempObj) {
          let obj = {
            projectName: i,
            count: tempObj[i]["total"]
          }
          returnArr.push(obj);
        }
      }
      return returnArr;
    },[reportProjects]);
  
    const breakDownByTimeSpent = useCallback((eventsArr, value) => {
      let tempObj = {};
      
      for(let i in eventsArr) {
        let timeSpent = Number(eventsArr[i].task.duration/3600);
        let startMoment = moment(eventsArr[i].task.startDateTime,moment.ISO_8601);
        let startOfWeekDate = startMoment.startOf("week").format("YYYY/MM/DD");
        if(value === "project" || value === "goal" || value === "combined" || value === "progress") {
          let projectName = "";
          let goalName = "";
          let projectColor ="";
          let goalOrder = 0;
          let projectObj = reportProjects.find( ({ key }) => key === eventsArr[i].task.projectID );
          
          if(projectObj) {
            if(projectObj.goal) {
              goalName = projectObj.goal.name;
              goalOrder = projectObj.goal.order;
            } else {
              goalName = "GoalNotAssigned";
              goalOrder = 99999;
            }
            projectName = projectObj.text;
            projectColor = projectObj.color;
          } else {
            goalName = "GoalNotAssigned";
            if (eventsArr[i].task.outlookNotAccepted) {
              projectName = "NotAccepted";
            } else if(defaultProjects[eventsArr[i].task.projectID]) {
              projectName = defaultProjects[eventsArr[i].task.projectID];
            } else {
              projectName = "Unknown Project";
            }
          }
  
          if(!tempObj[goalName]) {
            tempObj[goalName] = {};
            tempObj[goalName]["order"] = goalOrder
            tempObj[goalName]["total"] = 0;
            tempObj[goalName]["projects"] = {};
            tempObj[goalName]["weeks"] = {};
          }
          if(!tempObj[goalName]["projects"][projectName]) {
            tempObj[goalName]["projects"][projectName] = {};
            tempObj[goalName]["projects"][projectName]["color"] = projectColor;
            tempObj[goalName]["projects"][projectName]["timeSpent"] = timeSpent;
          } else {
            tempObj[goalName]["projects"][projectName]["timeSpent"] += timeSpent;
          }

          if(!tempObj[goalName]["weeks"][startOfWeekDate]) {
            tempObj[goalName]["weeks"][startOfWeekDate] = {};
            tempObj[goalName]["weeks"][startOfWeekDate]["timeSpent"] = timeSpent;
          } else {
            tempObj[goalName]["weeks"][startOfWeekDate]["timeSpent"] += timeSpent;
          }
          tempObj[goalName]["total"] += timeSpent;
        } else if(value === "difficulty" || value === "focus") {
          let taskValue = difficultyStrings[eventsArr[i].task[value]];
          if(value === "focus") {
            taskValue = focusStrings[eventsArr[i].task[value]];
          }
          if(!tempObj[taskValue]) {
            tempObj[taskValue] = timeSpent;
          } else {
            tempObj[taskValue]+=timeSpent;
          }
        } 
      } 
      let returnArr = [];
  
      if(value === "combined" || value === "project" || value === "progress") {
        for(let i in tempObj) {
          if(goalGrouping === 1 || value === "combined" || value === "project") {
            for(let y in tempObj[i]["projects"]) {
              let obj = {
                goalName: i,
                j: y,
                projectColor: tempObj[i]["projects"][y]["color"],
                order: tempObj[i]["order"],
                count: tempObj[i]["projects"][y]["timeSpent"]
              }
              returnArr.push(obj);
            }
          } else if(goalGrouping === 2 && value === "progress") {
            for(let y in tempObj[i]["weeks"]) {
              let obj = {
                goalName: i,
                j: y,
                order: tempObj[i]["order"],
                count: tempObj[i]["weeks"][y]["timeSpent"]
              }
              returnArr.push(obj);
            }
          }
        }
        returnArr.sort((a,b) => {
          return a.order - b.order;
        });
      } else if (value === "goal") {
        for(let i in tempObj) {
          let obj = {
            j: i,
            count: tempObj[i]["total"]
          }
          returnArr.push(obj);
        }
      } 
      return returnArr;
    },[reportProjects, goalGrouping]);

    useEffect(()=>{
      setAliasProjects(reportProjects.map((project)=>({...project, checked:undefined})));
    },[reportProjects]);

    useEffect(()=>{
      let tasksArr = [...eventsRangeFiltered];
      let _groupByValues = [];
      let _managerLoLValues = [];
      for(let i in tasksArr) {
        let groupByValue = "No "+groupByTitle+" data";
        if(tasksArr[i].task[groupByTitle]) {
          groupByValue = tasksArr[i].task[groupByTitle];
        } 
        tasksArr[i].task.userID = groupByValue;
        if(!_groupByValues.find((obj) => obj.value === groupByValue)) {
          let valueToPush = {"value":groupByValue,"label":groupByValue,"checked":"on"};
          if(groupByValue === "No "+groupByTitle+" data") {
            delete valueToPush.checked;
          }
          _groupByValues.push(valueToPush);
        }
        if(groupByTitle === "manager") {
          let managerLoLValue = "No LoL data";
          if(tasksArr[i].task["leaderofleader"]) {
            managerLoLValue = tasksArr[i].task["leaderofleader"];
          } 
          if(!_managerLoLValues.find((obj) => obj.value === managerLoLValue)) {
            let valueToPush = {"value":managerLoLValue,"label":managerLoLValue,"checked":"on"};
            if(managerLoLValue === "No LoL data") {
              delete valueToPush.checked;
            }
            _managerLoLValues.push(valueToPush);
          }
        }
      }
      setGroupByValues(_groupByValues);
      if(groupByTitle === "manager") {
        setManagerLoLValues(_managerLoLValues);
      }
      setEventsRangeFilteredByGroup(tasksArr);
      setIsLoadingGroupBy(false);
    },[eventsRangeFiltered,groupByTitle]);

    useEffect(()=>{
      for(let i in groupBySelection) {
        if(groupBySelection[i].checked === 'on') {
          setGroupByTitle(groupBySelection[i].value);
        }
      }
    },[groupBySelection]);

    useEffect(()=>{
      let groupByValuesArr = [];
      let managerLoLValuesArr = [];
      for(let i in groupByValues) {
        if(groupByValues[i].checked === 'on') {
          groupByValuesArr.push(groupByValues[i].value);
        }
      }
      if(groupByTitle === "manager") {
        for(let i in managerLoLValues) {
          if(managerLoLValues[i].checked === 'on') {
            managerLoLValuesArr.push(managerLoLValues[i].value);
          }
        }
      }
      let tasksArr = [...eventsRangeFilteredByGroup];
      tasksArr = tasksArr.filter((obj)=> {
        let returnVal = groupByValuesArr.includes(obj.task.userID);
          if(groupByTitle === "manager") {
            returnVal = returnVal && managerLoLValuesArr.includes(obj.task["leaderofleader"]);
          }
        return returnVal;
      });
      setEventsRangeFilteredByGroupValue(tasksArr);
      setIsLoadingGroupByValues(false);
      setIsLoadingManagerLoLValues(false);
    },[groupByValues, managerLoLValues, eventsRangeFilteredByGroup]);

    useEffect(()=>{
      let newTimeSpentlineDataByGroup = timeSpentTotalByDay(eventsRangeFilteredByGroupValue, "Team");
      setTimeSpentLineDataByGroup(newTimeSpentlineDataByGroup);
    },[eventsRangeFilteredByGroupValue,timeSpentTotalByDay]);
  
    useEffect(()=>{
      async function fetchTasks() {
        const startMoment = dateMath.parse(reportStart);
        const endMoment = dateMath.parse(reportEnd);
        let newTotalHoursByAlias = {};
        let newTagData = {};
        let startRange = startMoment;
        let endRange = endMoment;

        if(endRange.diff(startRange) >= 0) {
          let maxRange = endRange.diff(startRange,'days');
  
          setLoadProgress(0);
          setLoadMax(maxRange);
    
          let tasksArr = [];
          let projectArr = [];
          let aliasArr = [];
          let projectUnique = {};
          let aliasUnique = {};
          let dayUnique = {};
          for(let i in reportCalendars) {
            if(reportCalendars[i].checked === "on") {
              let calendarIDuserID = reportCalendars[i].value+""+userData.username;
              if(reportCalendars[i].sender) {
                calendarIDuserID = reportCalendars[i].value+""+reportCalendars[i].sender;
              }
              let nextToken = 1;
              while(nextToken) {
                let variableObj = { 
                  calendarIDuserID: calendarIDuserID,
                  startDateTime: {between: [startRange, endRange]},
                  limit: 1000
                };
                if(nextToken !== 1) {
                  variableObj.nextToken = nextToken;
                }
    
                let query = { query: listTaskByCalendarIDuserID, variables: variableObj};
                if(reportType === "Team") {
                  delete variableObj.calendarIDuserID;
                  variableObj.calendarID = JSON.parse(userSettings.team).calendarID;
                  query = { query: `
                    query ListTaskByCalendarID(
                      $calendarID: ID!
                      $startDateTime: ModelStringKeyConditionInput
                      $limit: Int
                      $nextToken: String
                    ) {
                      listTaskByCalendarID(
                        calendarID: $calendarID
                        startDateTime: $startDateTime
                        limit: $limit
                        nextToken: $nextToken
                      ) {
                        items {
                          id
                          name
                          startDateTime
                          endDateTime
                          duration
                          status
                          blocker
                          timeSpent
                          userID
                          projectID
                          teamID
                          calendarID
                          owner
                          tags
                          outlookNotAccepted
                        }
                        nextToken
                      }
                    }
                    `, variables: variableObj}
                } 
                
                const apiData = await API.graphql(query);
                let returnObj = apiData.data.listTaskByCalendarIDuserID;
                if(reportType === "Team") {
                  returnObj = apiData.data.listTaskByCalendarID;
                }
                let _fetchedTasks = returnObj.items.map(task => {
                  if(teamGroupByData[task.userID]) {
                    task.tenure = teamGroupByData[task.userID].tenure;
                    task.level = teamGroupByData[task.userID].level;
                    task.manager = teamGroupByData[task.userID].manager;
                    task.leaderofleader = teamGroupByData[task.userID].leaderofleader;
                  }
                  if(task.outlookNotAccepted) {
                    task.projectID = "4";
                  }

                  if(task.projectID != "4") {
                    if(!newTotalHoursByAlias[task.userID]) {
                      newTotalHoursByAlias[task.userID] = 0;
                    }
                    newTotalHoursByAlias[task.userID] += parseInt(task.duration)/3600;
                  }
                  
                  if(reportType === "Team") {
                    let findProject = teamProjects.find(obj => obj.value === task.projectID);
                    if(findProject) {
                      let projectObj = {};
                      projectObj.id = findProject.value;
                      projectObj.name = findProject.text;
                      projectObj.tags = findProject.tags;
                      projectObj.notes = findProject.notes;
                      projectObj.color = findProject.color;
                      projectObj.goalID = findProject.goalid;
                      projectObj.goal = findProject.goal;
                      projectObj.calendarID = findProject.calendarids;
                      task.project = projectObj;
                    }
                  }
                  for(let i in task.tags) {
                    let tagValue = task.tags[i];
                    if(!newTagData[tagValue]) {
                      newTagData[tagValue] = {};
                    }
                    if(!newTagData[tagValue][task.name]) {
                      newTagData[tagValue][task.name] = 0;
                    }
                    newTagData[tagValue][task.name]++;
                  }
                  return {
                    "title" : task.name,
                    "task" : task,
                    "start": moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").toDate(),
                    "end": (task.status === 2 ? moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(task.timeSpent, 'seconds').toDate() : moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(task.duration, 'seconds').toDate())
                  };
                });
                if(_fetchedTasks[0]) {
                  setLoadProgress(Math.abs(startRange.diff(moment(_fetchedTasks[0].start),'days')));
                }
                _fetchedTasks.forEach((obj)=>{
                  let day = moment(obj.task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").format('LL');
                  if(!dayUnique[day]) {
                    dayUnique[day] = 1;
                  }
                  if(obj.task.userID) {
                    if(!aliasUnique[obj.task.userID]) {
                      aliasUnique[obj.task.userID] = 1;
                      aliasArr.push({
                        "value" : obj.task.userID,
                        "key": obj.task.userID,
                        "text": obj.task.userID.replaceAll("amazonfederate_",""),
                        "label": obj.task.userID.replaceAll("amazonfederate_",""),
                        "checked" : 'on',
                      })
                    }
                  }
                  if(obj.task.project) {
                    if(!projectUnique[obj.task.project.id]) {
                      projectUnique[obj.task.project.id] = {};
                      let project = obj.task.project;
                      projectArr.push({
                        "value" : project.id,
                        "key": project.id,
                        "text" : project.name,
                        "label" : project.name,
                        "checked" : 'on',
                        "tags": project.tags,
                        "notes": project.notes,
                        "color": project.color,
                        "goalid": project.goalID,
                        "goal": project.goal,
                        "team": "false",
                        "calenderids": project.calendarID
                      });
                    } 
                    if(!projectUnique[obj.task.project.id][obj.task.userID]) {
                      projectUnique[obj.task.project.id][obj.task.userID] = Number(obj.task.duration/3600);
                    } else {
                      projectUnique[obj.task.project.id][obj.task.userID] += Number(obj.task.duration/3600);
                    }
                  } else if (obj.task.outlookNotAccepted) {
                    if(!projectUnique["4"]) { 
                      projectUnique["4"] = {};
                      projectArr.push({"key":"4","value":"4","text":"NotAccepted", "label":"NotAccepted","checked":''});
                    } 
                    if(!projectUnique["4"][obj.task.userID]) {
                      projectUnique["4"][obj.task.userID] = Number(obj.task.duration/3600);
                    } else {
                      projectUnique["4"][obj.task.userID] += Number(obj.task.duration/3600);
                    }
                  } else if(defaultProjectsMap[obj.task.projectID]) {
                    if(!projectUnique[obj.task.projectID]) {
                      projectUnique[obj.task.projectID] = {}
                      projectArr.push(defaultProjectsMap[obj.task.projectID]);
                    }
                    if(!projectUnique[obj.task.projectID][obj.task.userID]) {
                      projectUnique[obj.task.projectID][obj.task.userID] = Number(obj.task.duration/3600);
                    } else {
                      projectUnique[obj.task.projectID][obj.task.userID] += Number(obj.task.duration/3600);
                    }
                  }
                });
                nextToken = returnObj.nextToken;
                tasksArr = [...tasksArr,..._fetchedTasks];
              }
            }
          }
          setProjectByAlias(projectUnique);
          setReportEvents(tasksArr);
          setReportProjects(projectArr);
          setReportAlias(aliasArr);
          setRealAvgDays(Object.keys(dayUnique).length);
          setIsLoading(false);
          setLoadProgress(maxRange);
          setTotalHoursByAlias(newTotalHoursByAlias);
          setTagData(newTagData);
        }
      }
      if(reportCalendars) {
        fetchTasks();
      }
    },[reportCalendars,reportStart,reportEnd,reportType,setReportProjects,setReportAlias,setReportEvents,defaultProjectsMap,teamProjects,userData.username,userSettings.team,teamGroupByData]);

    useEffect(()=>{
      let newTimeSpentlineData = timeSpentTotalByDay(eventsRangeFiltered, "Primary");
      let newGoalLineData = breakDownByTimeSpent(eventsRangeFiltered, "progress");
      setTimeSpentLineData(newTimeSpentlineData);
      setGoalLineData(newGoalLineData);
      setIsLoadingCalendars(false);
      setIsLoadingProjects(false);
      setIsLoadingAlias(false);
    },[eventsRangeFiltered,breakDownByTimeSpent,timeSpentTotalByDay]);
  
    const onSelectedTabChanged = (id) => {
      setSelectedTabId(id);
    };
  
    return (
      <EuiPageTemplate restrictWidth="100%" template="empty">
        <EuiPageTemplate.Sidebar minWidth={325}>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem
              css={css`
                  padding: calc(${euiTheme.size.xs});
              `}
            >
              <EuiTourStep
                {...euiTourSteps[4]}
              >
                <EuiFormRow label="Show reports for the following date ranges">
                  <EuiSuperDatePicker
                    isLoading={isLoading}
                    start={reportStart}
                    end={reportEnd}
                    onTimeChange={onTimeChange}
                    onRefresh={onRefresh}
                    showUpdateButton={false}
                    width="full"
                    commonlyUsedRanges={[
                      { start: 'now/w', end: 'now', label: 'This Week' },
                      { start: 'now-1w/w', end: 'now/w', label: 'Last Week' },
                      { start: 'now/M', end: 'now', label: 'This Month' },
                      { start: 'now-1M/M', end: 'now/M', label: 'Last Month' },
                      { start: 'now/y', end: 'now', label: 'This Year' },
                      { start: 'now-1y/y', end: 'now/y', label: 'Last Year' },
                    ]}
                  />
                </EuiFormRow>
              </EuiTourStep>
              <EuiSpacer/>
              <EuiProgress value={loadProgress} max={loadMax} size="s" />
            </EuiFlexItem>
            <EuiFlexItem css={css`
              ${logicals['min-width']}: 25px;
            `}>
              {isLoading && <EuiLoadingChart size="l" /> }
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem
              css={css`
                  padding: calc(${euiTheme.size.xs});
              `}>
              <EuiPopover
                panelPaddingSize="m"
                button={
                  <EuiButton
                    iconType="arrowDown"
                    iconSide="right"
                    onClick={() => setSelectProjectPopoverOpen(!isSelectProjectPopoverOpen)}
                  >
                    Filter Projects
                  </EuiButton>
                }
                isOpen={isSelectProjectPopoverOpen}
                closePopover={() => setSelectProjectPopoverOpen(false)}
              >
                <EuiButton
                  size="xs"
                  onClick={() => {
                    let newReportProjects = reportProjects.map(obj => {
                      obj.checked = 'on';
                      return obj;
                    });
                    setIsLoadingProjects(true);
                    setReportProjects(newReportProjects);
                  }}
                >Select All</EuiButton>&nbsp;
                <EuiButton
                  size="xs"
                  onClick={() => {
                    let newReportProjects = reportProjects.map(obj => {
                      obj.checked = undefined;
                      return obj;
                    });
                    setIsLoadingProjects(true);
                    setReportProjects(newReportProjects);
                  }}
                >Unselect All</EuiButton>
                <EuiSpacer size="m" />
                <EuiSelectable
                  searchable
                  searchProps={{
                    placeholder: 'Filter list',
                    compressed: true,
                  }}
                  options={reportProjects}
                  onChange={(newOptions) => {
                    setIsLoadingProjects(true);
                    setReportProjects(newOptions);
                  }}
                >
                  {(list, search) => (
                    <div style={{ width: 240 }}>
                      <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                      {list}
                    </div>
                  )}
                </EuiSelectable>
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isLoadingProjects && <EuiLoadingChart size="l" />}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem 
              css={css`
                  padding: calc(${euiTheme.size.xs});
              `}>
                <EuiPopover
                  panelPaddingSize="m"
                  button={
                    <EuiButton
                      iconType="arrowDown"
                      iconSide="right"
                      onClick={() => setSelectCalendarPopoverOpen(!isSelectCalendarPopoverOpen)}
                    >
                      Filter Calendars
                    </EuiButton>
                  }
                  isOpen={isSelectCalendarPopoverOpen}
                  closePopover={() => setSelectCalendarPopoverOpen(false)}
                >
                  <EuiButton
                    size="xs"
                    onClick={() => {
                      let newReportCalendars = reportCalendars.map(obj => {
                        obj.checked = 'on';
                        return obj;
                      });
                      setIsLoadingCalendars(true);
                      setReportCalendars(newReportCalendars);
                    }}
                  >Select All</EuiButton>&nbsp;
                  <EuiButton
                    size="xs"
                    onClick={() => {
                      let newReportCalendars = reportCalendars.map(obj => {
                        obj.checked = undefined;
                        return obj;
                      });
                      setIsLoadingCalendars(true);
                      setReportCalendars(newReportCalendars);
                    }}
                  >Unselect All</EuiButton>
                  <EuiSpacer size="m" />
                  <EuiSelectable
                    searchable
                    searchProps={{
                      placeholder: 'Filter list',
                      compressed: true,
                    }}
                    options={reportCalendars}
                    onChange={(newOptions) => {
                      setIsLoadingCalendars(true);
                      setReportCalendars(newOptions);
                    }}
                  >
                    {(list, search) => (
                      <div style={{ width: 400 }}>
                        <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                        {list}
                      </div>
                    )}
                  </EuiSelectable>
                </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isLoadingCalendars && <EuiLoadingChart size="l" />}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem
              css={css`
                  padding: calc(${euiTheme.size.xs});
              `}>
              <EuiPopover
                panelPaddingSize="m"
                button={
                  <EuiButton
                    iconType="arrowDown"
                    iconSide="right"
                    onClick={() => setSelectAliasPopoverOpen(!isSelectAliasPopoverOpen)}
                  >
                    Filter Alias
                  </EuiButton>
                }
                isOpen={isSelectAliasPopoverOpen}
                closePopover={() => setSelectAliasPopoverOpen(false)}
              >
                <EuiButton
                  size="xs"
                  onClick={() => {
                    let newReportAlias = reportAlias.map(obj => {
                      obj.checked = 'on';
                      return obj;
                    });
                    setIsLoadingAlias(true);
                    setReportAlias(newReportAlias);
                  }}
                >Select All</EuiButton>&nbsp;
                <EuiButton
                  size="xs"
                  onClick={() => {
                    let newReportAlias = reportAlias.map(obj => {
                      obj.checked = undefined;
                      return obj;
                    });
                    setIsLoadingAlias(true);
                    setReportAlias(newReportAlias);
                  }}
                >Unselect All</EuiButton>
                <EuiSpacer size="m" />
                <EuiSelectable
                  searchable
                  searchProps={{
                    placeholder: 'Filter list',
                    compressed: true,
                  }}
                  options={reportAlias}
                  onChange={(newOptions) => {
                    setIsLoadingAlias(true);
                    setReportAlias(newOptions);
                  }}
                >
                  {(list, search) => (
                    <div style={{ width: 240 }}>
                      <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                      {list}
                    </div>
                  )}
                </EuiSelectable>
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isLoadingAlias && <EuiLoadingChart size="l" />}
            </EuiFlexItem>
          </EuiFlexGroup>
          {reportType === 'Team' && <>
            <EuiFlexGroup>  
              <EuiFlexItem 
                css={css`
                    padding: calc(${euiTheme.size.xs});
                `}>
                <EuiPopover
                  panelPaddingSize="none"
                  button={
                    <EuiButton
                      iconType="arrowDown"
                      iconSide="right"
                      onClick={() => setSelectGroupByPopoverOpen(!isSelectGroupByPopoverOpen)}
                    >
                      Group By
                    </EuiButton>
                  }
                  isOpen={isSelectGroupByPopoverOpen}
                  closePopover={() => setSelectGroupByPopoverOpen(false)}
                >
                  <EuiSelectable
                    searchable
                    searchProps={{
                      placeholder: 'Filter list',
                      compressed: true,
                    }}
                    singleSelection={"always"}
                    options={groupBySelection}
                    onChange={(newOptions) => {
                      setIsLoadingGroupBy(true);
                      setGroupBySelection(newOptions);
                    }}
                  >
                    {(list, search) => (
                      <div style={{ width: 400 }}>
                        <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                        {list}
                      </div>
                    )}
                  </EuiSelectable>
                </EuiPopover>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {isLoadingGroupBy && <EuiLoadingChart size="l" />}
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem 
                css={css`
                    padding: calc(${euiTheme.size.xs});
                `}>
                <EuiPopover
                  panelPaddingSize="m"
                  button={
                    <EuiButton
                      iconType="arrowDown"
                      iconSide="right"
                      onClick={() => setSelectGroupByValuesPopoverOpen(!isSelectGroupByValuesPopoverOpen)}
                    >
                      Filter {groupByTitle.charAt(0).toUpperCase() + groupByTitle.slice(1)}
                    </EuiButton>
                  }
                  isOpen={isSelectGroupByValuesPopoverOpen}
                  closePopover={() => setSelectGroupByValuesPopoverOpen(false)}
                >
                  <EuiButton
                    size="xs"
                    onClick={() => {
                      let newOptions = groupByValues.map(obj => {
                        obj.checked = 'on';
                        return obj;
                      });
                      setIsLoadingGroupByValues(true);
                      setGroupByValues(newOptions);
                    }}
                  >Select All</EuiButton>&nbsp;
                  <EuiButton
                    size="xs"
                    onClick={() => {
                      let newOptions = groupByValues.map(obj => {
                        obj.checked = undefined;
                        return obj;
                      });
                      setIsLoadingGroupByValues(true);
                      setGroupByValues(newOptions);
                    }}
                  >Unselect All</EuiButton>
                  <EuiSpacer size="m" />
                  <EuiSelectable
                    searchable
                    searchProps={{
                      placeholder: 'Filter list',
                      compressed: true,
                    }}
                    options={groupByValues}
                    onChange={(newOptions) => {
                      setIsLoadingGroupByValues(true);
                      setGroupByValues(newOptions);
                    }}
                  >
                    {(list, search) => (
                      <div style={{ width: 400 }}>
                        <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                        {list}
                      </div>
                    )}
                  </EuiSelectable>
                </EuiPopover>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {isLoadingGroupByValues && <EuiLoadingChart size="l" />}
              </EuiFlexItem>
            </EuiFlexGroup>
            {groupByTitle === "manager" && <EuiFlexGroup>
              <EuiFlexItem 
                css={css`
                    padding: calc(${euiTheme.size.xs});
                `}>
                <EuiPopover
                  panelPaddingSize="none"
                  button={
                    <EuiButton
                      iconType="arrowDown"
                      iconSide="right"
                      onClick={() => setSelectManagerLoLValuesPopoverOpen(!isSelectManagerLoLValuesPopoverOpen)}
                    >
                      Filter by LoL
                    </EuiButton>
                  }
                  isOpen={isSelectManagerLoLValuesPopoverOpen}
                  closePopover={() => setSelectManagerLoLValuesPopoverOpen(false)}
                >
                  <EuiSelectable
                    searchable
                    searchProps={{
                      placeholder: 'Filter list',
                      compressed: true,
                    }}
                    options={managerLoLValues}
                    onChange={(newOptions) => {
                      setIsLoadingManagerLoLValues(true);
                      setManagerLoLValues(newOptions);
                    }}
                  >
                    {(list, search) => (
                      <div style={{ width: 400 }}>
                        <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                        {list}
                      </div>
                    )}
                  </EuiSelectable>
                </EuiPopover>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {isLoadingManagerLoLValues && <EuiLoadingChart size="l" />}
              </EuiFlexItem>
            </EuiFlexGroup>}
          </>}
          <EuiFlexGroup>
            <EuiFlexItem 
              css={css`
                  padding: calc(${euiTheme.size.xs});
              `}>
                <CSVLink data={taskList} filename="TimeBlokExport" withTimeStamp>
                  <EuiButton>Export CSV</EuiButton>
                </CSVLink>
              </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPageTemplate.Sidebar>
        <EuiPageTemplate.Section>
          <EuiPanel color="transparent"> 
            <EuiTabs>
              <EuiTab
                key={"Overall"}
                onClick={() => onSelectedTabChanged("Overall")}
                isSelected={"Overall" === selectedTabId}
              >
                Overall
              </EuiTab>
              {reportType === "Team" && <EuiTab
                key={"Team"}
                onClick={() => onSelectedTabChanged("Team")}
                isSelected={"Team" === selectedTabId}
              >
                Team
              </EuiTab>}
              {reportType === "Team" && <EuiTab
                key={"Team Adoption"}
                onClick={() => onSelectedTabChanged("Team Adoption")}
                isSelected={"Team Adoption" === selectedTabId}
              >
                Team Adoption
              </EuiTab>}
              {reportType === "Team" && <EuiTab
                key={"Project"}
                onClick={() => onSelectedTabChanged("Project")}
                isSelected={"Project" === selectedTabId}
              >
                Project
              </EuiTab>}
              {reportType === "Team" && <EuiTab
                key={"Tag Analysis"}
                onClick={() => onSelectedTabChanged("Tag Analysis")}
                isSelected={"Tag Analysis" === selectedTabId}
              >
                Tag Analysis
              </EuiTab>}
            </EuiTabs>
            <EuiSpacer/>
            {selectedTabId === 'Tag Analysis' && <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <TagAnalysis tagData={tagData}/>
              </EuiFlexItem>
            </EuiFlexGroup>
            </>}
            {selectedTabId === 'Team Adoption' && <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <AdoptionTable teamGroupByData={teamGroupByData} totalHoursByAlias={totalHoursByAlias}/>
              </EuiFlexItem>
            </EuiFlexGroup>
            </>}
            {selectedTabId === 'Project' && <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <ProjectTable projectByAlias={projectByAlias} aliasProjects={aliasProjects} setAliasProjects={setAliasProjects}/>
              </EuiFlexItem>
            </EuiFlexGroup>
            </>}
            {selectedTabId === "Team" &&
            <>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h4>TimeSpent</h4>
                  </EuiText>
                  <EuiSpacer/>  
                  <Chart size={{ height: 475 }}>
                    <Settings
                      showLegend
                      legendPosition="right"
                    />
                    <BarSeries
                      id="timeSpent"
                      name="timeSpent"
                      xScaleType={ScaleType.Ordinal}
                      yScaleType={ScaleType.Linear}
                      data={timeSpentLineDataByGroup}
                      xAccessor={'x'}
                      yAccessors={['y']}
                      splitSeriesAccessors={['j']}
                      color={barSeriesColorAccessor}
                    />
                    <Axis 
                      id="bottom-axis" 
                      position="bottom" 
                      showGridLines
                    />
                    <Axis 
                      id="left-axis" 
                      position="left" 
                      showGridLines 
                      title="Timespent (%)"
                    />
                  </Chart>
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer/>
            <EuiFlexGroup>
              <EuiFlexItem>
                <ExpandTableBreakDown type={"Project Breakdown by TimeSpent"} events={eventsRangeFilteredByGroupValue} projects={reportProjects}/>
              </EuiFlexItem>
            </EuiFlexGroup>
            </>}
            {selectedTabId === "Overall" &&
            <>
            <EuiFlexGroup>
              <EuiFlexItem 
                css={css`
                  padding: calc(${euiTheme.size.s});
                  ${logicals['width']}: 10%;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h4>Statistics</h4>
                  </EuiText>
                  <EuiSpacer/>  
                  <EuiStat
                      title={getTotal(eventsRangeFiltered, "timeSpent", "duration").toFixed(1)}
                      description="Total Amount of Hours Logged"
                      textAlign="center"              
                    />
                  <EuiStat
                      title={(getTotal(eventsRangeFiltered, "timeSpent", "duration")/realAvgDays).toFixed(1)}
                      description="Avg Hours per Day"
                      textAlign="center"              
                    />
                  <EuiStat
                      title={eventsRangeFiltered.length}
                      description="Total Tasks Logged"
                      textAlign="center"              
                    />  
                  <EuiStat
                      title={(eventsRangeFiltered.length/realAvgDays).toFixed(1)}
                      description="Avg Tasks per Day"
                      textAlign="center"              
                  />  
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem css={css`
                  padding: calc(${euiTheme.size.s});
                  ${logicals['width']}: 90%;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h4>TimeSpent</h4>
                  </EuiText>
                  <EuiSpacer/>  
                  <Chart size={{ height: 375 }}>
                    <BarSeries
                      id="timeSpent"
                      name="timeSpent"
                      xScaleType={ScaleType.Ordinal}
                      yScaleType={ScaleType.Linear}
                      data={timeSpentLineData}
                      xAccessor={'x'}
                      yAccessors={['y']}
                      splitSeriesAccessors={['j']}
                      stackAccessors={['g']}
                      color={barSeriesColorAccessor}
                    />
                    <Axis 
                      id="bottom-axis" 
                      position="bottom" 
                      showGridLines
                    />
                    <Axis 
                      id="left-axis" 
                      position="left" 
                      showGridLines 
                      title="Hours spent"
                    />
                  </Chart>
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer/>
            {reportType !== "Team" &&
            <EuiFlexGroup>
              <EuiFlexItem 
                css={css`
                  padding: calc(${euiTheme.size.s});
                  ${logicals['width']}: 100%;
                `}>
                <EuiPanel>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={9} style={{ textAlign: 'center' }}>
                      <EuiTourStep
                        {...euiTourSteps[9]}
                      >
                        <EuiText style={{ textAlign: 'center' }}>
                          <h4>Goal Progress</h4>
                        </EuiText>
                      </EuiTourStep>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <span>
                        <EuiButton
                          style={{ textAlign: 'right' }}
                          aria-label="GroupbyWeek" 
                          onClick={()=>{
                            setGoalGrouping(2);
                          }}
                        >Group by Week</EuiButton>
                        &nbsp;
                        <EuiButton
                          style={{ textAlign: 'right' }}
                          aria-label="GroupByProjects" 
                          onClick={()=>{
                            setGoalGrouping(1);
                          }}
                        >Group by Projects</EuiButton>
                      </span>
                    </EuiFlexItem>
                  </EuiFlexGroup>  
                  <EuiSpacer/>  
                  <Chart size={{height: 600}}>
                    <Settings
                      rotation={90}
                      showLegend
                      theme={{
                        barSeriesStyle: {
                          displayValue: {
                            fontSize: 12,
                            fontStyle: 'bold',
                            fill: { textBorder: 1 },
                            offsetX: 10,
                            offsetY: 0,
                            alignment: {
                              vertical: 'middle',
                            },
                          },
                        },
                      }}
                    />
                    <BarSeries
                      id="goalProgress"
                      name="goalProgress"
                      xScaleType={ScaleType.Ordinal}
                      yScaleType={ScaleType.Linear}
                      data={goalLineData}
                      xAccessor={'goalName'}
                      yAccessors={['count']}
                      splitSeriesAccessors={['j']}
                      stackAccessors={goalGrouping === 1 ? ['j'] : []}
                      color={barSeriesColorAccessor}
                      displayValueSettings={{showValueLabel: true}}
                    />
                    <Axis 
                      id="bottom-axis" 
                      position="bottom" 
                      showGridLines
                    />
                    <Axis 
                      id="left-axis" 
                      position="left" 
                      showGridLines 
                    />
                  </Chart>
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>}
            <EuiFlexGroup>
              <EuiFlexItem grow={3}
                css={css`
                  padding: calc(${euiTheme.size.s});
                  ${logicals['width']}: 33%;
                `}>
                <EuiPanel style={{height: "650px"}}>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h4>Project Breakdown by TimeSpent&nbsp;
                    <EuiButtonIcon 
                      display="base" 
                      iconType="lensApp" 
                      aria-label="Expand" 
                      onClick={()=>{
                        setExpandModal("Project Breakdown by TimeSpent");
                      }}
                    /></h4>
                  </EuiText>
                  <EuiSpacer/>  
                  <Chart size={{height: 500}}>
                  <Settings
                      theme={partitionTheme}
                    />
                    <Partition
                      data={breakDownByTimeSpent(eventsRangeFiltered, "project")}
                      valueAccessor={d => Number(d.count)}
                      valueFormatter={d => (Math.round(d * 10) / 10)+`h`}
                      layers={[
                        {
                          groupByRollup: d => d.j,
                          shape: {
                            fillColor: d => {
                              let projectObj = reportProjects.find( ({ text }) => text === d.dataName );
                              if(projectObj) {
                                if(projectObj.color) {
                                  return projectObj.color;
                                }
                              }
                              return euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length];
                            },
                          },
                        },
                      ]}
                      config={{
                        ...euiPartitionConfig,
                        clockwiseSectors: false, // For correct slice order
                      }}
                    />
                  </Chart>
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem grow={3}
              css={css`
                padding: calc(${euiTheme.size.s});
                ${logicals['width']}: 33%;
              `}>
                {reportType !== "Team" && <EuiPanel style={{height: "650px"}}>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h4>Goal Breakdown by TimeSpent&nbsp;
                    <EuiButtonIcon 
                      display="base" 
                      iconType="lensApp" 
                      aria-label="Expand" 
                      onClick={()=>{
                        setExpandModal("Goal Breakdown by TimeSpent");
                      }}
                    /></h4>
                  </EuiText>
                  <EuiSpacer/>  
                  <Chart size={{height: 500}}>
                  <Settings
                      theme={partitionTheme}
                    />
                    <Partition
                      data={breakDownByTimeSpent(eventsRangeFiltered, "goal")}
                      valueAccessor={d => Number(d.count)}
                      valueFormatter={d => (Math.round(d * 10) / 10)+`h`}
                      layers={[
                        {
                          groupByRollup: d => d.j,
                          shape: {
                            fillColor: d => euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length],
                          },
                        },
                      ]}
                      config={{
                        ...euiPartitionConfig,
                        clockwiseSectors: false, // For correct slice order
                      }}
                    />
                  </Chart>
                </EuiPanel>}
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                  padding: calc(${euiTheme.size.m});
                  ${logicals['width']}: 33%;
                `}>
                {reportType !== "Team" && <EuiPanel style={{height: "650px"}}>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h4>Combined Breakdown by TimeSpent&nbsp;
                    <EuiButtonIcon 
                      display="base" 
                      iconType="lensApp" 
                      aria-label="Expand" 
                      onClick={()=>{
                        setExpandModal("Combined Breakdown by TimeSpent");
                      }}
                    /></h4>
                  </EuiText>
                  <EuiSpacer/>  
                  <Chart size={{height: 500}}>
                  <Settings
                      theme={partitionTheme}
                    />
                    <Partition
                      data={breakDownByTimeSpent(eventsRangeFiltered, "combined")}
                      layout={PartitionLayout.sunburst}
                      valueAccessor={d => Number(d.count)}
                      valueFormatter={d => (Math.round(d * 10) / 10)+`h`}
                      layers={[
                        {
                          groupByRollup: d => d.goalName,
                          shape: {
                            fillColor: d => euiChartTheme.theme.colors.vizColors[d.sortIndex % euiChartTheme.theme.colors.vizColors.length],
                          },
                        },
                        {
                          groupByRollup: d => d.j,
                          shape: {
                            fillColor: d => {
                              let projectObj = reportProjects.find( ({ text }) => text === d.dataName );
                              if(projectObj) {
                                if(projectObj.color) {
                                  return projectObj.color;
                                }
                              }
                              return euiChartTheme.theme.colors.vizColors[d.parent.sortIndex % euiChartTheme.theme.colors.vizColors.length];
                            },
                          },
                        }
                      ]}
                      config={{
                        ...euiPartitionConfig,
                        clockwiseSectors: false, // For correct slice order
                      }}
                    />
                  </Chart>
                </EuiPanel>}
              </EuiFlexItem>
            </EuiFlexGroup>
            </>}
          </EuiPanel>
          <EuiSpacer/>
          <ExpandReport expandModal={expandModal} setExpandModal={setExpandModal} events={eventsRangeFiltered} projects={reportProjects} breakDownByTimeSpent={breakDownByTimeSpent} breakDownByValue={breakDownByValue} euiChartTheme={euiChartTheme} euiPartitionConfig={euiPartitionConfig}/>
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    );
}